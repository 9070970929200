import React, { useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useDispatch, useSelector } from 'react-redux';
import {
  accountAttribute,
  clearAttributes,
  clearStoreState,
  myAccountListingDetails,
  storeSelector,
} from '../../../store/feature/storeSlice';
import Image from 'next/image';
import PopUp from '../../Shared/PopUp/PopUp';
import { authSelector } from '../../../store/feature/authSlice';
import { useRouter } from 'next/dist/client/router';
import { edit_product_click } from './editProduct2';
import EditListingSuccess from './EditListingSuccess';
import Modal from '../../Shared/Modal/Modal';
import { configsSelector } from '../../../store/feature/configsSlice';
import { stock_text } from '../../Shared/Constant/TextConstant/addlistingConstant';
import dynamic from 'next/dynamic';
import Attributes from './Attributes';
import {
  category_serialization,
  fetch_all_categories,
} from '../../../constant/fetch_all_categories';
import { ReactSortable } from 'react-sortablejs';
import ModalBasic from '../../Shared/Modal/ModalBesic';
import CropImage from '../../Shared/Image/CropImage';
import { Add_product_text } from '../../../constant/Translation/AddProduct_text';
import { dynamic_translation_set } from '../../../constant/Translation/translation_set';
import SearchAddress from '../../Shared/SearchAddress';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import SelectDropDown from '../../Shared/SelectDropDown';
import { slug as SlugChecker } from 'github-slugger';
import OrderTypeSelection from './OrderTypeSelectioon';
import { validateImageSize } from '../../../utils/ImageSizeValidate';
import { changeDateFormat } from '../../Shared/Constant/Constant';
import moment from 'moment';
import SwitchBox from '../../../UI/FormFields/Switch';
import Composer2 from '../../Shared/Composer/Composer2';
import Dimension from './FormItems/Dimension';
import Timezone from './Timezone';

const EditProductForm = ({ order_type, setOrderType }) => {
  const [title, setTitle] = useState('');

  const [slug, setSlug] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState(0);
  const [goal_price, setGoalPrice] = useState(0);

  const [shippingCharge, setShippingCharge] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [offerPercent, setOfferPercent] = useState(0);
  const [meta_title, setMetaTitle] = useState('');
  const [meta_keyword, setMetaKeyword] = useState('');
  const [meta_description, setMetaDescription] = useState(' ');

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [attributeData, setAttributeData] = useState([]);
  const [currency, setCurrency] = useState(null);
  const [product_address, setProduct_address] = useState('');
  const [tags, setTags] = useState('');
  const [coordinates, setCoordinates] = useState(null);
  const [addressSearchKey, setAddressSearchKey] = useState('');
  const [full_location, setFullLocation] = useState(null);
  const [all_images, setAll_images] = useState([]);
  const [imagePath, setImagePath] = useState([]);
  const [files, setFiles] = useState([]);
  const [fullFile, setFullFile] = useState(null);
  const [showError, setShowError] = useState(false);
  const [error_message, setError_message] = useState('');
  const [editProductLoading, setEditProductLoading] = useState(false);
  const [allCategories, setAllCategories] = useState(null);
  const [startAt, setStartAt] = useState(null);
  const [endAt, setEndAt] = useState(null);
  const [weight_uom, setWeightUom] = useState('kilogram');
  const [weight_uom_list, setWeightUomList] = useState([
    { label: 'Kilogram', value: 'kilogram' },
  ]);
  const [size_uom, setSizeUom] = useState('centimeter');
  const [size_uom_list, setSizeUomList] = useState([
    { label: 'cm', value: 'centimeter' },
  ]);
  const [weight_value, setWeightValue] = useState('');
  const [height_value, setHeightValue] = useState(null);
  const [width_value, setWidthValue] = useState(null);
  const [length_value, setLengthValue] = useState(null);
  const [time_zone, setTimezone] = useState(null);
  const [seller_protection_fee_enabled, setSellerProtectionFeeEnabled] =
    useState(false);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const { auth_key } = useSelector(authSelector);
  const [editorData, setData] = useState();

  const {
    listing_configs,
    isError,
    errorMessage,
    currencies,
    listing_categories,
    my_account_listing_details,
    attributes,
  } = useSelector(storeSelector);
  const { genral_configs, MARKETPLACE_MODULES, MARKETPLACE_FLAVOURS } =
    useSelector(configsSelector);

  const dispatch = useDispatch();
  const router = useRouter();
  const accountId = router.query.account_id;
  const productId = router.query.product_id;

  // all_categories
  useEffect(() => {
    if (listing_categories?.length > 0) {
      setAllCategories(fetch_all_categories(listing_categories, true));
    }
  }, [listing_categories]);

  // Use Effect functions
  useEffect(() => {
    setAttributeData([]);
    if (my_account_listing_details) {
      setTitle(my_account_listing_details?.title);
      setOrderType(
        my_account_listing_details?.order_type
          ? my_account_listing_details?.order_type
          : 'listings'
      );
      setSlug(my_account_listing_details?.slug);
      setDescription(my_account_listing_details?.description || '');
      setTags(
        my_account_listing_details?.tags?.map((tag) => tag?.name).join(',') ||
          ''
      );
      setTimezone(my_account_listing_details?.time_zone);
      setMetaTitle(my_account_listing_details?.meta_title);
      setMetaKeyword(my_account_listing_details?.meta_keyword);
      setMetaDescription(my_account_listing_details?.meta_description);
      setPrice(Number(my_account_listing_details?.list_price.amount));
      setGoalPrice(Number(my_account_listing_details?.goal_price?.amount));
      setShippingCharge(
        Number(my_account_listing_details?.shipping_charges.amount)
      );
      setQuantity(Number(my_account_listing_details?.stock));
      setWeightUom(
        my_account_listing_details?.weight_uom
          ? my_account_listing_details?.weight_uom
          : weight_uom_list?.[0]?.value
      );
      setSizeUom(
        my_account_listing_details?.size_uom
          ? my_account_listing_details?.size_uom
          : size_uom_list?.[0]?.value
      );
      setWeightValue(my_account_listing_details?.weight_value);
      setWidthValue(my_account_listing_details?.width);
      setHeightValue(my_account_listing_details?.height);
      setLengthValue(my_account_listing_details?.length);

      setSellerProtectionFeeEnabled(
        my_account_listing_details?.seller_protection_fee_enabled === undefined
          ? false
          : my_account_listing_details?.seller_protection_fee_enabled
      );
      setOfferPercent(Number(my_account_listing_details?.offer_percent));
      setAll_images(
        my_account_listing_details?.images.map((item, index) => {
          return { id: index, path: item, full_file: null };
        })
      );

      my_account_listing_details?.start_at &&
        setStartAt(
          changeDateFormat(my_account_listing_details?.start_at, 'YYYY-MM-DD')
        );
      my_account_listing_details?.end_at &&
        setEndAt(
          changeDateFormat(my_account_listing_details?.end_at, 'YYYY-MM-DD')
        );
      setCoordinates({
        latitude: my_account_listing_details?.coordinates?.latitude,
        longitude: my_account_listing_details?.coordinates?.longitude,
      });
      setAddressSearchKey(
        my_account_listing_details?.location?.formatted_address
      );
      TYPE_CONSTANT.ADDRESS_CONFIGS.map_type == 'google_map' &&
        setFullLocation(my_account_listing_details?.location);
      setSelectedCategory(
        category_serialization(my_account_listing_details?.categories[0])
      );
      dispatch(
        accountAttribute({
          prams: {
            category_id: my_account_listing_details?.category_id[0],
            type: 'listings',
          },
          authKey: auth_key,
        })
      );
      if (my_account_listing_details?.attributes?.length > 0) {
        my_account_listing_details?.attributes.filter((attr) => {
          if (
            (attr.field_type === 1 || attr.field_type === 2) &&
            attr.values?.length > 0
          ) {
            setAttributeData((prevState) => [
              ...prevState,
              {
                id: attr.id,
                values: attr.values.map((item) => item.id),
              },
            ]);
          } else if (
            (attr.field_type === 3 || attr.field_type === 4) &&
            attr.values?.length > 0
          ) {
            setAttributeData((prevState) => [
              ...prevState,
              { id: attr.id, values: attr.values.map((item) => item) },
            ]);
          } else if (
            (attr.field_type === 5 ||
              attr.field_type === 13 ||
              attr.field_type === 14) &&
            attr.values?.length > 0
          ) {
            setAttributeData((prevState) => [
              ...prevState,
              {
                values: attr.values,
                id: attr.id,
              },
            ]);
          } else if (
            (attr.field_type === 6 ||
              attr.field_type === 7 ||
              attr.field_type === 8 ||
              attr.field_type === 9 ||
              attr.field_type === 10 ||
              attr.field_type === 11 ||
              attr.field_type === 12) &&
            attr.values?.length > 0
          ) {
            setAttributeData((prevState) => [
              ...prevState,
              {
                values: attr.values,
                id: attr.id,
              },
            ]);
          }
        });
      }
    }

    //
    return () => {
      dispatch(clearAttributes());
    };
  }, [my_account_listing_details]);

  useEffect(() => {
    if (currencies !== null) {
      setCurrency(currencies[0].id);
    }
  }, [currencies]);

  // on select category
  const onSelectCategory = (item) => {
    setSelectedCategory(item);
    setAttributeData(null);
    dispatch(
      accountAttribute({
        prams: { category_id: item.value, type: 'listings' },
        authKey: auth_key,
      })
    );
  };

  //Image Upload func:
  const imageButtonClick = () => {
    if (
      all_images.length !== parseInt(listing_configs.listing_pictures_count)
    ) {
      document.getElementById('imageButtonInput').click();
    } else {
      setShowError(true);
      setError_message(
        dynamic_translation_set(
          Add_product_text.addproduct.alert_max_image_upload,
          parseInt(listing_configs.listing_pictures_count)
        )
      );
    }
  };

  // Image
  const [imageFormOpen, setImageFormOpen] = useState(false);
  const [image_data, setImageData] = useState(null);
  // imageUpload
  const imageUpload = async (data) => {
    if (validateImageSize(data)) {
      setAll_images([
        {
          id: data.id,
          path: data.path,
          name: data.name.replace(/\s/g, '-'),
          type: data.type,
          full_file: data.base64,
        },
        ...all_images,
      ]);
    } else {
      setShowError(true);
      setError_message(Add_product_text.addproduct.image_size_alert);
      setEditProductLoading(false);
      return false;
    }
  };

  const imageDelete = async (id) => {
    const all_images_filter = all_images.filter((image) => image.id !== id);
    setAll_images(all_images_filter);
  };

  const closePopUP = () => {
    dispatch(clearStoreState());
    setShowError(false);
    setError_message('');
  };

  return (
    <div className=" w-full  ">
      {/* Error popup */}
      {(showError || isError) && (
        <OutsideClickHandler
          onOutsideClick={() => {
            (showError || isError) &&
              (setShowError(false),
              setError_message(''),
              dispatch(clearStoreState()));
          }}
        >
          <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
            <div className="">
              <PopUp
                message={error_message || errorMessage}
                closePopUP={closePopUP}
              />
            </div>
          </div>
        </OutsideClickHandler>
      )}
      {/* Success Popup */}
      {showSuccessMessage && (
        <Modal>
          <OutsideClickHandler
            onOutsideClick={() => {
              setShowSuccessMessage(false);
              dispatch(myAccountListingDetails({ slug: productId, id: true }));
            }}
          >
            <EditListingSuccess
              message={Add_product_text.addproduct.updated_product_successfully}
              setShowSuccessMessage={setShowSuccessMessage}
              dispatch={dispatch}
              productId={productId}
              auth_key={auth_key}
              my_account_listing_details={my_account_listing_details}
            />
          </OutsideClickHandler>
        </Modal>
      )}
      {/* Form */}
      <h1 className=" text-start  title-h1 mb-6">
        {Add_product_text.addproduct.edit_listing_title}
      </h1>
      {/* Order type */}{' '}
      {TYPE_CONSTANT.THEME === 0 && (
        <div className="mb-6">
          <OrderTypeSelection
            order_type={order_type}
            set_order_type={setOrderType}
          />
        </div>
      )}
      <div className="grid grid-cols-1 gap-6">
        {/* Listing Image */}
        <div className="block">
          <span className="text-gray-700  text-lg">
            {Add_product_text.addproduct.imagefield}
          </span>
          <input
            id="imageButtonInput"
            type="file"
            value={''}
            className=" hidden"
            accept=".png, .jpg ,.svg , .gif, .webp, .jpeg"
            placeholder=""
            onChange={(e) => {
              e.stopPropagation();
              setImageFormOpen(true);
              if (e.target.files && e.target.files.length > 0) {
                setImageData(e.target.files[0]);
              }
            }}
            multiple
          />
          <div
            className={[
              'mt-2  flex flex-row   flex-wrap ',
              all_images?.length > 0 && 'gap-4',
            ].join(' ')}
          >
            <ReactSortable
              list={all_images}
              setList={setAll_images}
              animation={150}
              group="cards"
              // onChange={(order, sortable, evt) => {}}
              // onEnd={(evt) => {}}
              className="flex   items-center flex-wrap gap-4"
            >
              {all_images.length !== 0 &&
                all_images?.map((singleImage) => {
                  return (
                    <div
                      key={singleImage.id}
                      className=" relative w-28 cursor-move"
                    >
                      <img
                        src={singleImage.path}
                        alt="store image"
                        className="w-28 h-28  object-cover shadow-c-xsm"
                      />
                      <button
                        className=" absolute -top-2 -right-2 text-primary "
                        onClick={() => imageDelete(singleImage.id)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </button>
                    </div>
                  );
                })}
            </ReactSortable>

            {/* {(all_images.length === 0 ||
              all_images[0]?.full_file !== undefined) && ( */}
            <button
              className=" w-28  h-28 flex justify-center items-center    bg-gray-100 text-sm rounded-card  "
              onClick={() => imageButtonClick()}
            >
              {Add_product_text.addproduct.add_image}
            </button>
            {/* )} */}
          </div>
          {listing_configs?.listing_pictures_count && (
            <p className=" text-sm mt-2 font-normal text-default_gray">
              {dynamic_translation_set(
                Add_product_text.addproduct.alert_max_image_upload,
                listing_configs?.listing_pictures_count
              )}
            </p>
          )}
        </div>

        {/* Listing Title */}
        <label className="block">
          <span className="text-gray-700  text-lg">
            {Add_product_text.addproduct.title}
          </span>
          <input
            value={title}
            type="text"
            className="
                    mt-0
                    block
                    w-full
                    px-0.5 
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
            placeholder=""
            onChange={(e) => setTitle(e.target.value)}
          />
        </label>

        {/* Listing Slug */}
        {listing_configs?.enable_slug && (
          <label className="block">
            <span className="text-gray-700  text-lg">
              {Add_product_text.addproduct.listing_slug}
            </span>
            <input
              value={slug}
              type="text"
              className="
                    mt-0
                    block
                    w-full
                    px-0.5 
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
              placeholder=""
              onChange={(e) => setSlug(e.target.value)}
            />
          </label>
        )}

        {/* Listing description */}
        {/* Description */}
        {listing_configs?.show_description != false && (
          <div className="block">
            <span className="text-gray-700  text-lg">
              {Add_product_text.addproduct.description}
            </span>
            {listing_configs?.listing_enable_formatted_text_description !=
            false ? (
              <Composer2
                contents={description}
                setContents={setDescription}
                mainClass={' border-b-2 border-gray-200 '}
              />
            ) : (
              <textarea
                value={description}
                className="
                    mt-0
                    block
                    w-full
                    px-0.5
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
                rows="4"
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            )}
          </div>
        )}

        {/* Listing meta title */}
        {listing_configs?.meta_title && (
          <label className="block">
            <span className="text-gray-700  text-lg">
              {Add_product_text.addproduct.listing_meta_title}
            </span>
            <input
              type="text"
              value={meta_title}
              className="
                    mt-0
                    block
                    w-full
                    px-0.5 
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
              placeholder="Set meta title for seo "
              onChange={(e) => setMetaTitle(e.target.value)}
            />
          </label>
        )}

        {/* Listing meta description */}
        {listing_configs?.meta_description && (
          <label className="block">
            <span className="text-gray-700  text-lg">
              {Add_product_text.addproduct.listing_meta_description}
            </span>
            <textarea
              value={meta_description}
              type="text"
              row={7}
              className="
                    mt-0
                    block
                    w-full
                    px-0.5 
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
              placeholder="Set meta description for listing"
              onChange={(e) => setMetaDescription(e.target.value)}
            />
          </label>
        )}

        {/* Listing meta keyword */}
        {listing_configs?.meta_keyword && (
          <label className="block">
            <span className="text-gray-700  text-lg">
              {Add_product_text.addproduct.listing_meta_keyword}
            </span>
            <input
              type="text"
              value={meta_keyword}
              className="
                    mt-0
                    block
                    w-full
                    px-0.5 
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
              placeholder="Set listing meta tags for seo"
              onChange={(e) => setMetaKeyword(e.target.value)}
            />
          </label>
        )}

        {/* Listing address */}
        {listing_configs?.listing_address_enabled && (
          <label className="block ">
            <span className="text-gray-700  text-lg">
              {Add_product_text.addproduct.address}
            </span>
            <SearchAddress
              setCoordinates={setCoordinates}
              addressSearchKey={addressSearchKey}
              setAddressSearchKey={setAddressSearchKey}
              full_location={full_location}
              setFullLocation={setFullLocation}
            />
          </label>
        )}
        {/*  */}
        <div className=" grid grid-cols-1 gap-6  md:grid-cols-2  md:justify-between">
          {/* Price */}
          {order_type !== 'donation' && (
            <label className="block relative">
              <span className="text-gray-700  text-lg">
                {Add_product_text.addproduct.price}
              </span>
              {/*  */}
              <div className=" relative mt-0    w-full px-0.5 border-0 border-b-2 border-gray-200 transition  duration-700  focus:ring-0 focus:border-primary flex items-center ">
                <select
                  className="   pl-2 pr-8 py-1 border-0  transition  duration-700 focus:ring-0 focus:border-primary text-sm   h-full "
                  onChange={(e) => setCurrency(e.target.value)}
                >
                  {currencies?.map((currency) => (
                    <option key={currency.id} value={currency.id}>
                      {currency.code}
                    </option>
                  ))}
                </select>
                <input
                  value={price}
                  type="number"
                  className="  grow w-full border-0 outline-none  text-input "
                  placeholder="1"
                  onChange={(e) => {
                    if (e.target.value >= 0) {
                      setPrice(e.target.value);
                    }
                  }}
                  onWheel={(event) => event.currentTarget.blur()}
                />
              </div>
            </label>
          )}
          {/* Goal Price*/}
          {order_type === 'donation' && (
            <div className="block relative">
              <span className="text-gray-700  text-lg">
                {Add_product_text.addproduct.goal_price}
              </span>
              <div className=" relative mt-0    w-full px-0.5 border-0 border-b-2 border-gray-200 transition  duration-700  focus:ring-0 focus:border-primary flex items-center ">
                <select
                  className="   pl-2 pr-8 py-1 border-0  transition  duration-700 focus:ring-0 focus:border-primary text-sm   h-full "
                  onChange={(e) => setCurrency(e.target.value)}
                >
                  {currencies?.map((currency) => (
                    <option key={currency.id} value={currency.id}>
                      {currency.code}
                    </option>
                  ))}
                </select>
                <input
                  value={goal_price}
                  type="number"
                  className="  grow w-full border-0 outline-none  text-input "
                  placeholder="1"
                  onChange={(e) => {
                    if (e.target.value >= 0) {
                      setGoalPrice(e.target.value);
                    }
                  }}
                  onWheel={(event) => event.currentTarget.blur()}
                />
              </div>
            </div>
          )}
          {/* Offer percente */}
          {listing_configs.hide_offer_percent && order_type !== 'donation' && (
            <label className="block">
              <span className="text-gray-700  text-lg">
                {Add_product_text.addproduct.offer_percent}
              </span>
              <input
                value={offerPercent}
                type="number"
                className="
                    mt-0
                    block
                    w-full
                    px-0.5 
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
                placeholder="1"
                onChange={(e) => {
                  if (e.target.value >= 0) {
                    setOfferPercent(e.target.value);
                  }
                }}
                onWheel={(event) => event.currentTarget.blur()}
              />
            </label>
          )}

          {/* Weight */}
          {listing_configs.listing_enable_weight &&
            order_type == 'listings' && (
              <label className="block relative">
                <span className="text-gray-700  text-lg">
                  {Add_product_text.addproduct.weight}
                </span>
                <div className=" relative mt-0    w-full px-0.5 border-0 border-b-2 border-gray-200 transition  duration-700  focus:ring-0 focus:border-primary flex items-center ">
                  <input
                    value={weight_value}
                    type="number"
                    className="  grow w-full border-0 outline-none  text-input "
                    placeholder="1"
                    onChange={(e) => {
                      if (e.target.value >= 0) {
                        setWeightValue(e.target.value);
                      }
                    }}
                    onWheel={(event) => event.currentTarget.blur()}
                  />
                  <select
                    className="   pl-2 pr-8 py-1 border-0  transition  duration-700 focus:ring-0 focus:border-primary text-sm   h-full "
                    onChange={(e) => setWeightUom(e.target.value)}
                  >
                    {weight_uom_list?.map((uom) => (
                      <option key={uom.value} value={uom.value}>
                        {uom.label}
                      </option>
                    ))}
                  </select>
                </div>
              </label>
            )}
          {/* Dimention */}
          {listing_configs.listing_enable_dimensions &&
            order_type == 'listings' && (
              <Dimension
                height_value={height_value}
                setHeightValue={setHeightValue}
                width_value={width_value}
                length_value={length_value}
                setLengthValue={setLengthValue}
                setWidthValue={setWidthValue}
                size_uom_list={size_uom_list}
                size_uom={size_uom}
                setSizeUom={setSizeUom}
              />
            )}

          {/* Shipping charge */}
          {listing_configs.show_shipping_charges &&
            order_type !== 'donation' && (
              <label className="block">
                <span className="text-gray-700  text-lg">
                  {Add_product_text.addproduct.shipping_charges}
                </span>
                <input
                  value={shippingCharge}
                  type="number"
                  className="
                    mt-0
                    block
                    w-full
                    px-0.5 
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
                  placeholder="1"
                  onChange={(e) => {
                    if (e.target.value >= 0) {
                      setShippingCharge(e.target.value);
                    }
                  }}
                  onWheel={(event) => event.currentTarget.blur()}
                />
              </label>
            )}

          {/* Stock */}
          {listing_configs.enable_stock && (
            <label className="block">
              <span className="text-gray-700  text-lg">
                {Add_product_text.addproduct.stock}
              </span>
              <input
                value={quantity}
                type="number"
                className="
                    mt-0
                    block
                    w-full
                    px-0.5 
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
                placeholder="1"
                onChange={(e) => {
                  if (e.target.value >= 0) {
                    setQuantity(e.target.value);
                  }
                }}
                onWheel={(event) => event.currentTarget.blur()}
              />
            </label>
          )}
          {/* Start date */}
          {order_type === 'donation' && (
            <label className="block">
              <span className="text-gray-700  text-lg">Start Date</span>
              <input
                value={startAt}
                type="date"
                className="
                    mt-0
                    block
                    w-full
                    px-0.5
                 border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
                onChange={(e) =>
                  setStartAt(moment(e.target.value).format('YYYY-MM-DD'))
                }
              />
            </label>
          )}

          {/* End  date*/}
          {order_type === 'donation' && (
            <label className="block">
              <span className="text-gray-700  text-lg">End Date</span>
              <input
                type="date"
                className="
                    mt-0
                    block
                    w-full
                    px-0.5
                 border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
                value={endAt}
                onChange={(e) =>
                  setEndAt(moment(e.target.value).format('YYYY-MM-DD'))
                }
              />
            </label>
          )}

          {order_type === 'events' && (
            <Timezone time_zone={time_zone} setTimeZone={setTimezone} />
          )}
        </div>

        {/* Tags  (this tag config is confusing) */}
        {listing_configs?.listing_hide_tags && (
          <label className="block">
            <span className="text-gray-700  text-lg">
              {Add_product_text.addproduct.tag_your_product}
            </span>
            <input
              type="text"
              value={tags}
              className="
                    mt-0
                    block
                    w-full
                    px-0.5 
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
              placeholder={Add_product_text.addproduct.multi_write_value_info}
              onChange={(e) => setTags(e.target.value)}
            />
          </label>
        )}

        {/*  seller_protection_fee */}
        {listing_configs?.listing_seller_protection_fee_enabled &&
          order_type == 'listings' && (
            <SwitchBox
              title={Add_product_text.addproduct.seller_protection_fee}
              current_value={seller_protection_fee_enabled}
              set_new_value={(vl) => setSellerProtectionFeeEnabled(vl)}
              tooltip={Add_product_text.addproduct.seller_protection_fee_info}
            />
          )}
        {/* Categories */}
        <label className="block">
          <span className="text-gray-700  text-lg">
            {Add_product_text.addproduct.category}
          </span>
          <SelectDropDown
            drop_down_values={allCategories?.map((item) => {
              return { label: item.name, value: item.id };
            })}
            setSelectedItem={(item) => {
              if (item) {
                onSelectCategory(item);
              } else {
                setSelectedCategory(null);
                setAttributeData(null);
                dispatch(clearAttributes());
              }
            }}
            selectedItem={selectedCategory}
          />
        </label>

        {/* Attributes */}
        <div className="  flex flex-col gap-6">
          <Attributes
            attributeData={attributeData}
            setAttributeData={setAttributeData}
            setShowError={setShowError}
            setErrormessage={setError_message}
          />
        </div>
      </div>
      {/*Edit Save Buttons */}
      <div className=" mt-9 flex justify-center ">
        <button
          className="btn-primary-fill gap-2   "
          id="edit-listing"
          onClick={() =>
            edit_product_click(
              all_images?.map((image) => {
                return image.path;
              }) || null, //this is images path.
              all_images,
              title,
              slug,
              meta_title,
              meta_description,
              meta_keyword,
              description,
              price,
              shippingCharge,
              offerPercent,
              quantity,
              coordinates,
              full_location,
              selectedCategory ? selectedCategory.value : null,
              attributeData,
              currency,
              setShowError,
              setError_message,
              dispatch,
              router,
              listing_configs,
              auth_key,
              accountId,
              productId,
              setEditProductLoading,
              setShowSuccessMessage,
              attributes,
              order_type,
              goal_price,
              startAt,
              endAt,
              weight_uom,
              weight_value,
              seller_protection_fee_enabled,
              tags,
              height_value,
              width_value,
              size_uom,
              length_value,
              time_zone
            )
          }
          disabled={editProductLoading}
        >
          {editProductLoading && (
            <svg
              className="animate-spin -ml-1  h-5 w-5  "
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
          {Add_product_text.addproduct.update_product}
        </button>
      </div>
      <ModalBasic
        title="Image Edit"
        id="image-edit"
        modalOpen={imageFormOpen}
        setModalOpen={setImageFormOpen}
        main_div_width={500}
        main_div_height={500}
        closeByOutsideClick={false}
      >
        <CropImage
          imageUpload={imageUpload}
          image_data={image_data}
          setImageData={setImageData}
          imageFormOpen={imageFormOpen}
          setImageFormOpen={setImageFormOpen}
        />
      </ModalBasic>
    </div>
  );
};

export default EditProductForm;
