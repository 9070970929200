/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  getDatesArray,
  getTimeDifference,
} from '../../Shared/Constant/Constant';

// import Swiper core and required modules
import Warning from '../../Shared/PopUp/Warning';
import { authSelector } from '../../../store/feature/authSlice';
import { Listing_text } from '../../../constant/Translation/Listing';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';
import axios from 'axios';
import { CurrencyDollarIcon } from '@heroicons/react/outline';

const PricingRuleSelect = ({
  selected_pricing,
  setSelectedPricing,
  selectedScheduleTimeIndex,
  styles,
  listing_id,
  schedule_id,
  variant_id,
  setCartQuantity,
  stocks_left,
  data,
}) => {
  const [pricing_rules_list, setPricingRules] = useState([]);

  //
  const fetch_list = () => {
    setPricingRules([]);
    axios
      .post('/api/pricing_rules', {
        listing_id: listing_id,
        listing_schedule_id: schedule_id,
      })
      .then((res) => {
        const list = res.data.listing_price_rules?.filter(
          (pr) => pr.rule_value === 0 || pr?.rule_value <= stocks_left
        );
        setPricingRules(list);
        setSelectedPricing(list?.[0]);
        setCartQuantity(list?.[0]?.rule_value);
      });
  };
  useEffect(() => {
    if (listing_id && (schedule_id || variant_id)) {
      fetch_list();
    } else {
      setPricingRules(null);
    }
  }, [listing_id, schedule_id, variant_id]);

  return (
    pricing_rules_list?.length > 0 && (
      <div className="w-full ">
        {data?.purchase_title && (
          <p style={get_dynamic_styles(styles?.title_style)}>
            {data?.purchase_title}
          </p>
        )}

        <div className="w-full min-h-[200px] mt-5 ">
          {pricing_rules_list?.map((item, index) => {
            return (
              <div
                key={index}
                className={[
                  selected_pricing?.id !== item?.id
                    ? 'w-full min-h-[60px] px-4 py-[16px] relative rounded-button overflow-hidden  flex items-center my-4 shadow-c-sm  border border-transparent cursor-pointer hover:border-seceondary gap-3'
                    : 'w-full min-h-[60px]  px-4 py-[16px] relative rounded-button overflow-hidden  flex items-center my-4 shadow-c-sm cursor-pointer transition duration-700  border border-seceondary  ring ring-primary ring-offset-1  gap-3',
                ].join(' ')}
                onClick={() => {
                  setSelectedPricing(item);
                  setCartQuantity(item?.rule_value);
                }}
              >
                <CurrencyDollarIcon className=" w-6 h-6 text-black  " />
                <div className="  ">
                  <p className="text-black font-semibold">{item.title}</p>
                  <p className=" text-default_gray font-normal  text-xs">
                    {item.description}
                  </p>
                  <p className=" text-black font-normal  text-xs">
                    {item.list_price?.formatted}
                  </p>
                </div>
                <input
                  className={
                    selected_pricing?.id !== item?.id
                      ? '  cursor-pointer'
                      : ' cursor-pointer text-primary  focus:ring-primary focus:text-primary'
                  }
                  checked={selected_pricing?.id === item?.id ? true : false}
                  type="radio"
                  name=""
                  id=""
                />
              </div>
            );
          })}
        </div>
      </div>
    )
  );
};

export default PricingRuleSelect;
